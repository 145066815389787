
import * as React from "react";
import PostForm from "./post-form";
import PostDownload from "./post-download";


type Props = {
  //submit
  submitHandler?: any;
  submitting?: any;
  set_submitting?: any;
  processing?: number;
  set_processing?: any;
  set_changes?: any;
  changes?: any;
  max_files?: any;

  //post info
  post_id?: any;
  itemdata?: any;
  itemdataisLoading?: any;
  itemdataisError?: any;
  show_graphic?: any;
  form_reset?: any;
  set_form_reset?: any;
  actionText?:any;
  postcache?:any;
  set_postcache?:any;
  typevalue?:any;
  set_typevalue?:any;

  //inherited info
  ownerdata?: any;
  userdata?: any;

  navtype?: string;
  helptype?: string;

  modal_type?: string;
}

function PostFormContainer({
  //submitting
   submitHandler
  ,submitting
  ,set_submitting
  ,processing
  ,set_processing
  ,set_changes
  ,changes
  ,max_files

  //post info
  ,post_id
  ,itemdata
  ,itemdataisLoading
  ,itemdataisError
  ,show_graphic
  ,form_reset
  ,set_form_reset
  ,actionText
  ,postcache
  ,set_postcache
  ,typevalue
  ,set_typevalue

  //inherited info
  ,ownerdata
  ,userdata
  ,navtype
  ,helptype
  
  , modal_type
}: Props) {

  if (modal_type == "download") {
    return (<>
                    <PostDownload
                       submitHandler={submitHandler}
                       submitting={submitting}
                       set_submitting={set_submitting}
                       processing={processing}
                       set_processing={set_processing}
                       set_changes={set_changes}
                       changes={changes}
                       max_files={max_files}

                       //post info
                       post_id={post_id}
                       itemdata={itemdata}
                       itemdataisLoading={itemdataisLoading}
                       itemdataisError={itemdataisError}

                       ownerdata={ownerdata}
                       userdata={userdata}

                       show_graphic={false}
                       form_reset={form_reset}
                       set_form_reset={set_form_reset}
                       actionText={actionText}
                       postcache={postcache}
                       set_postcache={set_postcache}
                       navtype={navtype}
                       helptype={helptype}

                       typevalue={typevalue}
                       set_typevalue={set_typevalue}
                     />
      </>
      )
  }

return (<>
                <PostForm
                       submitHandler={submitHandler}
                       submitting={submitting}
                       set_submitting={set_submitting}
                       processing={processing}
                       set_processing={set_processing}
                       set_changes={set_changes}
                       changes={changes}
                       max_files={max_files}

                       //post info
                       post_id={post_id}
                       itemdata={itemdata}
                       itemdataisLoading={itemdataisLoading}
                       itemdataisError={itemdataisError}

                       ownerdata={ownerdata}
                       userdata={userdata}

                       show_graphic={false}
                       form_reset={form_reset}
                       set_form_reset={set_form_reset}
                       actionText={actionText}
                       postcache={postcache}
                       set_postcache={set_postcache}
                       navtype={navtype}
                       helptype={helptype}

                       typevalue={typevalue}
                       set_typevalue={set_typevalue}
                     />
        </>
        )
}

export default PostFormContainer



