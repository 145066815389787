import { reportHandler_admin_email } from "./reportHandler_admin_email";
   
   export function handleReportAdmin (
        chat_id
        ,chat_text
        ,chatter_name
        ,chatter_id
        ,clicker_name
        ,clicker_id
    ) {

        reportHandler_admin_email({
             chat_id: chat_id
            ,chat_text: chat_text
            ,chatter_name: chatter_name
            ,chatter_id: chatter_id
            ,reporter_name: clicker_name
            ,reporter_id: clicker_id
        })

    }