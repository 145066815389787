import toast from "react-simple-toasts";
import { hasData } from "../hasData";

export const approveHandler = async (id,action,set_cache) => {
    
        if (hasData(id)) {
          const res = await fetch(`/api/private/post_comment/approve?id=${id}&action=${action}`);;
          const json = await res.json()
          if (json) {
            const { action } = json[0][0]
            
            if (action==1) { 
              toast(`Comment has been approved.`, { time: 3000, className: '', clickable: true, clickClosable: false });
            } else {
              toast(`Comment has been denied. If this commenter becomes a problem, please consider blocking them from commenting.`, { time: 3000, className: '', clickable: true, clickClosable: false });
            }
          }
        }

        set_cache(+ new Date())
      
}