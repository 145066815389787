import { blockactions } from "@/lib/config";
import toast from "react-simple-toasts";
import { hasData } from "../hasData";

    //BLOCKS
    export const blockHandler = async (owner_id,target_id,username,action,isConfirmed) => {

      const confirmed = await isConfirmed(`Are you sure you want to ${blockactions.filter((e)=> e?.action == action)[0]?.action_short} ${username} ${blockactions.filter((e)=> e?.action == action)[0]?.js_amount}?`);
      // const confirmed = await isConfirmed({title:"Are you sure lol?",body:'this is not reversable',confirm:'lol'});
     if (confirmed) {
          if (hasData(target_id)) {
            const res = await fetch(`/api/private/user-block/insert?owner_id=${owner_id}&target_id=${target_id}&action=${action}`);;
            const json = await res.json()
            if (json) {
              const { outcome } = json[0][0]
              //console.log(outcome)
              if (outcome>=1) { 
                toast(`${username} has been ${blockactions.filter((e)=> e?.action == action)[0]?.action_past} ${blockactions.filter((e)=> e?.action == action)[0]?.js_amount}.`, { time: 3000, className: '', clickable: true, clickClosable: false });
              } else {
                toast(`You dont have permission to do that.`, { time: 3000, className: '', clickable: true, clickClosable: false });
              }
            }
          }
        }
      }