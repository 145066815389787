import { approveHandler } from "@/lib/utils/moderation/approveHandler"

function PostCommentsApproval({
     isAdmin
    ,comment_id
    ,set_cache
}) {



    

    return (<>
        {!isAdmin &&
        <div
            className="w-full italic text-center"
        >
        Pending Moderator Approval...
        </div>
        }

        {isAdmin &&
        <div className="flex items-center content-center w-full justify-center gap-2 border-t pt-4 border-gray-700">
            <div
                onClick={(e)=> approveHandler(comment_id,1,set_cache)}
                className="rounded-full bg-green-600 hover:bg-green-500 text-white px-4 py-1 font-bold cursor-pointer"
            >
            Approve Comment
            </div>
            <div
                onClick={(e)=> approveHandler(comment_id,0,set_cache)}
                className="rounded-full bg-red-600 hover:bg-red-500 text-white px-4 py-1  cursor-pointer"
            >
            Deny
            </div>
        </div>
        }
    </>)
}

export default PostCommentsApproval