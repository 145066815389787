
import SvgJsx from "@/components/templateux/svg/svg-jsx";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import Popup from "reactjs-popup";
import PopupMenuOption from "@/components/templateux/popup-menu-option";
import { useRouter } from "next/router";
import { getTimestamp } from "@/lib/utils/getTimestamp";
import { ModalContext } from "@/components/context/modal-wrapper";
import { ifNull } from "@/lib/utils/ifNull";


export default function PostMenuOptions({
   item_id
  ,item_name
  ,shrink=false
  ,userdata
  ,owner_id
  ,timestamp
  ,session
  ,openModal
  ,closeModal
  ,cache
  ,set_cache
  ,permaurl
  ,isAdmin
  ,isContributor
  ,post_date
  ,post_type
  ,deleteHandler
  ,stickyHandler
  ,transcribeHandler
  ,bol_transcribe
  ,bol_replay_host
  ,transcribe_status
  ,bol_sticky
  ,file
  ,duration
  ,content_id
  ,content_table
  ,owner_name
  ,avatar_url
  ,start_date
  ,stream_status
}) {

  const {isOpen,set_isOpen} = useContext(ModalContext)


  const [isMounted,set_isMounted] = useState(false)

  useEffect(() => {
    set_isMounted(true)
  }, [])

  const router = useRouter()


  const ref = useRef(null);
  //const openMenu = () => {if (ref.current) ref.current.open()};
  const closeMenu = () => {if (ref.current) ref.current.close()};
  //const toggleMenu = () => {if (ref.current) ref.current.toggle()};

  const PopupTrigger = <div 
                        aria-haspopup="true"
                        className="menu-item w-5 h-5 flex-0 "
                        > 
                          <SvgJsx 
                            type='outline' 
                            icon='dots-v' 
                            className={`cursor-pointer  flex-0 mr-1 rounded-md w-5 h-5
                                          text-gray-400 hover:text-gray-200`} 
                            title='More'
                          />  
                        </div>


  return (
    <div className="menu flex items-center content-center  flex-0  ">
      {!isMounted &&
      PopupTrigger
      }
      {isMounted &&
      <Popup
        trigger={PopupTrigger}
        position="left top"
        on="click"
        closeOnDocumentClick
        closeOnEscape
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: '0px', border: 'none',backgroundColor: 'transparent' }}
        arrow={false}
        nested
        ref={isMounted ? ref : null}
      >
        <div className="menu flex flex-col gap-y-1 rounded-md shadow-md p-2
                      bg-gray-800 "
        >

        
          <PopupMenuOption
            isDisplay={true}
            onClick={()=>{
              closeMenu();
              router.push(permaurl);
            }}
            icon="link-sm"
            title="Permalink"
          />
        d


          {/* EDIT */}
          {isAdmin &&
          <>

          <PopupMenuOption
            isDisplay={true}
            onClick={()=>{
              closeMenu();
              openModal("form",item_id,post_type);
            }}
            icon="pencil-sm"
            title="Edit"
          />
          
          <PopupMenuOption
            isDisplay={(post_type > 0)}
            onClick={()=>{
              closeMenu();
              stickyHandler(item_id);
            }}
            icon="thumbtack-sm"
            title={bol_sticky?.toString() == "0" ? "Sticky Post" : "Unsticky Post"}
          />


          <PopupMenuOption
            isDisplay={isAdmin}
            onClick={()=>{
              closeMenu();
              deleteHandler(item_id);
            }}
            icon="trash-sm"
            title="Delete"
          />

          <div className="text-center text-xs mt-1 italic">
            {post_date}
          </div>
          
          </>
          }

        </div>
      </Popup>
      }
  </div>
  );


  
}