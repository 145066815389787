import times from 'ramda/src/times'
import ItemProfileStateContainer from './item-profile-state-container';

function ItemProfilePlaceholder({      
     display
    ,userdata
    ,shrink=true
    ,owner_name='Loading...'
    ,placeholder
    ,navtype
    ,helptype
}) {

    const mutateItemsFalse = (id) => {
        return false;
    }

    return (
        <>
        {times((i) => (
            <ItemProfileStateContainer
                key={i}
                itemdata={{
                     item_id:0 
                    ,item_name:'Loading data...'
                    ,item_followers:0
                    ,item_verified:0
                    ,me_following_them:0
                    ,me_following_them_status:1
                    ,them_following_me:0
                    ,them_following_me_status:1
                    ,start_date:+new Date
                    ,timestamp:+new Date
                     }}
                userdata={userdata}
                ownerdata={{
                    id:0
                    ,user_name:owner_name
                    ,bol_admin_help:0
                    ,bol_staff_help:0
                    ,staff_json:'[]'
                    ,block_json: '[]'
                  }}
                shrink={shrink}
                placeholder={placeholder}
                navtype={navtype}
                helptype={helptype}
            />
        

        ), display)}
        </>
    )
}

  
  export default ItemProfilePlaceholder
  