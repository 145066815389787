export default function ProfileFollowPost({
     classColor
    ,onClick
    ,message
    ,showCondition
}) {

    if (!showCondition) return (<></>)
    return (<>
                 <div className={`flex items-center content-center`}>
                          <div className={`${classColor} 
                            text-xs
                            rounded-md shadow cursor-pointer text-center 
                            px-2 py-1 
                            block 
                            
                            `}
                            onClick={onClick}
                          >
                            {message}
                          </div>
                      </div>
            </>)
}