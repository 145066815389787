
import Tabs from "@/components/templateux/tabs";
import { config } from "@/lib/config";
import { formatPlural } from "@/lib/utils/formatPlural";
import { hasData } from "@/lib/utils/hasData";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import { JSONpreparrayforurl } from "@/lib/utils/JSONpreparrayforurl";
import { Dialog, Transition } from "@headlessui/react";
import Link from "next/link";
import { Fragment, useContext, useEffect, useState } from "react";
import ProfileArray from "../item/profile-array";
import PostComments from "./post-comments";
import PostLikes from "./post-likes";
import PostStats from "./post-stats";
import PostOpens from "./post-opens";
import { hasDataArray } from "@/lib/utils/hasDataArrayElement";
import { useSession } from "next-auth/client";


function PostMeta({
     post_id
    ,profiledata
    ,itemdata
    ,slug
    ,tabs
    ,set_tabs
    ,router
    ,cache
    ,set_cache
    ,userdata
    ,nodata
    ,navtype="post"
    ,helptype="chat"
    ,likes
    ,comments
    ,set_comments
    ,opens
}) {



    const [session,loading] = useSession()

    let [isOpenLocal,set_isOpenLocal] = useState(false)

    const [show_comments,set_show_comments] = useState(profiledata?.bol_post_comments?.toString()!=="0" && itemdata?.bol_comments?.toString()=="1")
    const [show_likes,set_show_likes] = useState(itemdata?.bol_likes?.toString()=="1")
    const [show_opens,set_show_opens] = useState(itemdata?.bol_opens?.toString()=="1")
    const [show_replays,set_show_replays] = useState(itemdata?.bol_replay_host?.toString()=="1" && itemdata?.bol_replay_post?.toString()=="1" )
    const [show_transcript,set_show_transcript] = useState(itemdata?.bol_transcribe?.toString()=="1" )
    const [modal_panel,set_modal_panel] = useState({data:"[]",id:"users",type:"multiple",length:0,title: `User Menu`});
    const [single, set_single] = useState(null)
    const [multiple, set_multiple] = useState(null)

    const [chatusersarray_str,set_chatusersarray_str] = useState('0')
    const [modal_length,set_modal_length] = useState(1)
    const [transcribe_json,set_transcribe_json] = useState([])

    useEffect(()=>{
        set_show_comments(profiledata?.bol_post_comments?.toString()!=="0" && itemdata?.bol_comments?.toString()=="1")
        set_show_likes(itemdata?.bol_likes?.toString()=="1")
        set_show_opens(itemdata?.bol_opens?.toString()=="1")
        set_show_transcript(itemdata?.bol_transcribe?.toString()=="1")
        set_show_replays(itemdata?.bol_replay_host?.toString()=="1" && itemdata?.bol_replay_post?.toString()=="1" )
        set_transcribe_json(hasDataArray(itemdata?.transcribe_json) ? JSON.parse(itemdata?.transcribe_json) : [])
    },[
        itemdata
        ,profiledata
    ])

     //CHAT SINGLE USER DATA
  const { 
    data: commentsingleuserdata
  , loading: commentsingleuserdataisLoading
  , error: commentsingleuserdataisError
  , boundMutate: itemsMutateSingle
                  } = useSWRWrapper(
                    hasData(single) ? single : null //id
                    ,`/api/private/profile/select?id=${single}&cache=${cache}` //key
                    ,true //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,0 //refreshinterval (0=none)
                    ) 

 const { 
    data: commentmultipleuserdata
  , loading: commentmultipleuserdataisLoading
  , error: commentmultipleuserdataisError
  , boundMutate: itemsMutateMultiple
                  } = useSWRWrapper(
                    hasData(multiple) ? multiple : null //id
                    ,`/api/private/user/select-multiple?id=${profiledata?.id}&arr=${JSONpreparrayforurl(multiple)}&cache=${cache}` //key
                    ,false //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,0 //refreshinterval (0=none)
                    ) 
    
    function openModal(n_data,n_type,n_id,n_length,n_title) {
        if (n_type == "single") set_single(n_data);
        if (n_type == "multiple") set_multiple(n_data);
        set_cache(+new Date());
        set_modal_length(n_length);
        set_modal_panel({
                         data: n_data
                        ,type: n_type
                        ,id: n_id
                        ,length: n_length
                        ,title: n_title
                    });
        set_isOpenLocal(true);
    }

    function closeModal() {
        set_isOpenLocal(false)
    }

    if (itemdata?.length == 0) {
        return (<div
                    className="w-full text-center cursor-pointer"
                >
                    <Link href={`/${profiledata?.user_name?.toLowerCase()}/posts`}>
                        <a className='underline hover:no-underline'>
                            Return to all posts
                        </a>
                    </Link>
                </div>)
    }

    const tab_string = (has_transcript,has_replay,has_timestamps) => {

        let arrstring = []
            
 
        arrstring.push(
            show_comments
            ? {
                text:`${comments+likes} ${formatPlural('Response',(comments+likes))}`
                ,value:"response"
                }
            : {
                text:`0 Responses`
                ,value:"response"
                }
      
        )
        arrstring.push(
            show_opens
            ? {
                text:`${opens} ${formatPlural('Open',opens)}`
                ,value:"open"
                }
            : {
                text:`0 Opens`
                ,value:"open"
                }
        )

                  // ,{text:"Stats",value:"stat"}
                  if (has_transcript && show_transcript) arrstring.push({text:"Transcript",value:"transcript"})
                  if (has_replay && show_replays) arrstring.push({text:"Chat Replay",value:"replay"})
                  if (has_timestamps) arrstring.push({text:`${itemdata?.sum_timestamps} ${formatPlural('Timestamp',itemdata?.sum_timestamps)}`,value:"timestamp"})  
               


        return arrstring;

    }


    return(<>
        <div 
            className=" w-full  inline-block bg-gray-900  pb-2 -mt-2"
        >
            <div 
                className={`  m-2 rounded-md   bg-gray-800 shadow-md rounded-t-md`}
            >

                {/* TOP ROW */}
                <div 
                    className="flex items-center content-center text-left border-b  border-gray-700 p-2"
                >
                    <h3 className='' id='tabs'>
                        <div className="sm:block hidden">
                            <Tabs
                                tabarray={tab_string(itemdata?.bol_transcribe?.toString()=="1" 
                                                    && itemdata?.transcribe_status?.toString()=="1",
                                                    itemdata?.post_type?.toString()=="5"
                                                    && itemdata?.bol_replay_host?.toString()=="1",
                                                    itemdata?.post_type?.toString()=="5"
                                                    )
                                }
                                clickval={tabs}
                                set_clickval={set_tabs}
                                tab_style="pill"
                            />
                        </div>
                        <div className="sm:hidden block">
                            <Tabs
                                tabarray={tab_string(itemdata?.bol_transcribe?.toString()=="1" 
                                                    && itemdata?.transcribe_status?.toString()=="1",
                                                    itemdata?.post_type?.toString()=="5"
                                                    && itemdata?.bol_replay_host?.toString()=="1",
                                                    itemdata?.post_type?.toString()=="5"
                                                    )
                                }
                                clickval={tabs}
                                set_clickval={set_tabs}
                                tab_style="pillmobile"
                            />
                        </div>
                    </h3>
                </div>

                {/* TOP ROW */}
                <div 
                    className=""
                >

                  
                    
                    {/* Responses */}
                    {(tabs == 'response') && 
                    <>
                        {show_comments &&
                            <PostComments
                                post_id={post_id}
                                profiledata={profiledata}
                                slug={slug}
                                router={router}
                                cache={cache}
                                set_cache={set_cache}
                                userdata={userdata}
                                openModal={openModal}
                                set_comments={set_comments}
                            />
                        }
                        {!show_comments &&
                        <div className="flex justify-center">
                            <div className="flex items-center content-center text-center py-10 italic text-gray-200">
                                <div className="flex-0">
                                    <div className="rounded-full bg-black text-white">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5">
                                            <path fillRule="evenodd" d="M10 1a4.5 4.5 0 00-4.5 4.5V9H5a2 2 0 00-2 2v6a2 2 0 002 2h10a2 2 0 002-2v-6a2 2 0 00-2-2h-.5V5.5A4.5 4.5 0 0010 1zm3 8V5.5a3 3 0 10-6 0V9h6z" clipRule="evenodd" />
                                        </svg>
                                    </div>
                                </div>
                                <div className="ml-2 flex-0">
                                    Comments are currently disabled for this post.
                                </div>
                           </div>
                        </div>
                           
                        }
                    </>
                    }

                    {(tabs=='like') &&
                    <>
                        {show_likes &&
                        <PostLikes
                            post_id={post_id}
                            profiledata={profiledata}
                            slug={slug}
                            router={router}
                            cache={cache}
                            set_cache={set_cache}
                            openModal={openModal}
                        />
                        }
                        {!show_likes &&
                        <div className="text-center py-10 italic text-gray-200">
                            Likes are currently disabled for this post.
                        </div>
                        }
                    </>}

                    {(tabs=='open') &&
                    <>
                        {show_opens &&
                        <PostOpens
                            post_id={post_id}
                            profiledata={profiledata}
                            slug={slug}
                            router={router}
                            cache={cache}
                            set_cache={set_cache}
                            openModal={openModal}
                        />
                        }
                        {!show_opens &&
                        <div className="text-center py-10 italic text-gray-200">
                            Opens are currently disabled for this post.
                        </div>
                        }
                    </>}

                    {tabs=='stat' &&
                    <>
                    
                        <PostStats
                            post_id={post_id}
                            profiledata={profiledata}
                            slug={slug}
                            router={router}
                            cache={cache}
                            set_cache={set_cache}
                        />
                    
                    </>
                    }



                </div>

            </div>
        </div>


        <Transition appear show={isOpenLocal} as={Fragment}>
                    <Dialog
                    as="div"
                    open={isOpenLocal}
                    className="fixed inset-0 z-100 overflow-y-auto bg-[rgb(0,0,0,0.85)]"
                    onClose={closeModal}
                    >
                    
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                        >
                        <Dialog.Overlay className="fixed inset-0 " />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                        className="inline-block h-screen align-middle"
                        aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                        >
                        <div className="inline-block w-full border transform p-6 my-8 overflow-hidden text-left align-middle transition-all shadow-xl rounded-2xl
                                        bg-gray-900 border-gray-700">
                            
                            <div className="sm:flex items-center content-center">
                                <div className="flex-1">
                               
                                    <Dialog.Title
                                    as="h3"
                                    className="text-xl font-bold leading-6 
                                    text-white"
                                    >
                                      
                                      <div className="xs:flex items-center content-center">

                                        <div className={`${
                                        (["gifs","upload"]?.indexOf(modal_panel.id) > -1 && userdata?.contributor_level > 0)
                                        ? 'hidden xs:block' 
                                        : ''} 
                                        flex-1`} >
                                            {modal_panel?.title } 
                                        </div>

                                      </div>
                                           
                                    </Dialog.Title>
                                </div>
                                
                            </div>
                            
                            
                            
 
                            <div className="mt-4 w-full content-center item-center flex">
                            {['users','likes','opens','reports'].indexOf(modal_panel.id) > -1 &&
                            
                            <ProfileArray 

                                itemdata={{data: modal_panel.type == 'multiple' 
                                            ? commentmultipleuserdata 
                                            : [commentsingleuserdata]
                                        }}
                                    
                                isLoading={modal_panel.type == "multiple" 
                                            ? commentmultipleuserdataisLoading 
                                            : commentsingleuserdataisLoading}

                                isError={modal_panel.type == "multiple" 
                                            ? commentmultipleuserdataisError 
                                            : commentsingleuserdataisError}

                                sum_items={modal_panel.type == "multiple" 
                                            ? modal_length 
                                            : 1}
                                userdata={userdata}
                                shrink={true}
                                ownerdata={profiledata}
                                nodata={nodata}
                                navtype={navtype}
                                helptype={helptype}
                            />
                            }

                           

                           

                            
                            </div>
                            

                            <div className="mt-4 border-t pt-4 
                                            border-gray-700
                            ">
                            <div className="flex items-center content-center">
                                
                                <div className='flex-0'>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center px-4 py-2 mt-5text-xl font-medium text-white bg-red-500 hover:bg-red-400  border border-transparent rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                           
                            </div>
                            
                        </div>
                        </Transition.Child>
                    </div>
                    </Dialog>
                </Transition>


    </>)
}

export default PostMeta