
import { LoadingProcessing } from "@/components/templateux/loading/loading-processing";
import { config } from "@/lib/config";
import { getSlugFilter } from "@/lib/utils/getSlugFilter";
import { useSWRWrapper } from "@/lib/utils/hooks/swr-hooks";
import pageint from "@/lib/utils/pageint";
import { useEffect, useState } from "react";


function PostComments({
     post_id
     ,profiledata
    ,slug
    ,router
    ,cache
    ,set_cache
}) {

    const page = pageint(router.query.page)
  var slugfilter = getSlugFilter(slug)


  const [filter, set_filter] = useState(slugfilter)
  const [filterinput, set_filterinput] = useState(slugfilter)
  const [filteropen, set_filteropen] = useState(false)
  const csort = router.query.sort || config.itemsort
  const [sort, set_sort] = useState(csort)
  const cdir = router.query.dir || config.itemdir
  const [dir, set_dir] = useState(cdir)
  const [sortstr,set_sortstr] = useState(`${config.itemsort} ${config.itemdir}`)

  useEffect(() => {
    set_filter(slugfilter)
    set_filterinput(slugfilter)
    if (slugfilter !=='') {
      set_filteropen(true)
    } else {
      set_filteropen(false)
    }
}, [slugfilter])

    useEffect(() => {
      set_sort(csort)
  }, [csort])

  useEffect(() => {
    set_dir(cdir)
  }, [cdir])

  const [statarraydata_active,set_statarraydata_active] = useState([]);

  //POST LIKE DATA
  const { 
    data: statarraydata
  , loading: statarraydataisLoading
  , error: statarraydataisError
  //, boundMutate: itemsMutate
                  } = useSWRWrapper(
                    post_id //id
                    ,`/api/private/post_stat/select?id=${post_id}&page=${page}&sort=${sort}&dir=${dir}&cache=${cache}&q=${filter}` //key
                    ,true //retry?
                    ,config.max_retries //max number of retries
                    ,config.retry_interval //retry interval
                    ,0 //refreshinterval (0=none)
                    )

    useEffect(()=>{
        if (!statarraydataisLoading && !statarraydataisError) {
            set_statarraydata_active(statarraydata);
        }
    },[
        statarraydata
        ,statarraydataisLoading
        ,statarraydataisError
    ])

    return(<>

                {/* TOP ROW */}
                <div 
                    className="flex w-full flex-col items-center content-center text-left divide-y  divide-gray-700"
                >

                    {statarraydataisLoading && 
                    <div className="p-10">
                         <LoadingProcessing />
                    </div>}

                    {!statarraydataisLoading &&
                    <>
                    
                        

                     
                        <div className="p-10">Coming soon!</div>
                  

                    </>}


                
                </div>


    </>)
}

export default PostComments